import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export function Home() {
    const navigate = useNavigate();
    const handleClick = (e) => (e.detail === 2) ? navigate("/login") : setGradient(`linear-gradient(to right, ${colors[Math.floor(Math.random() * colors.length)]}, ${colors[Math.floor(Math.random() * colors.length)]})`);;

    const [gradient, setGradient] = useState(`linear-gradient(to right,rgb(18, 230, 219),rgb(76, 14, 128))`);
    const colors = [
        ['#ff9966', '#0000FF'], // Coral to Pink
        ['#66ccff', '#00FF00'], // Light Blue to Blue
        ['#99ff99', '#FF0000'], // Light Green to Green
        ['#000000', '#ff9933'], // Orange to Dark Orange
        ['#0044FF', '#9966cc']  // Violet to Dark Violet
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            const randomColorIndex = Math.floor(Math.random() * colors.length);
            const [color1, color2] = colors[randomColorIndex];
            setGradient(`linear-gradient(to right, ${color1}, ${color2})`);
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{
            textAlign: 'center',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: gradient,
            transition: 'background 1.5s ease' // Smooth transition for background change
        }}>
            <div onClick={handleClick} style={{
                padding: 20,
                margin: 20,
                borderRadius: 10,
                backgroundColor: 'white',
                boxShadow: '5px 5px 10px #111',
                cursor:'pointer',
                fontWeight: 'bold',
                color: '#555'
            }}>
                Sito in costruzione
            </div>
        </div>
    );
}
